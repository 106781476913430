<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on }">
        <v-btn :disabled="user.id !== 1" text v-on="on">
          <v-icon left>mdi-plus</v-icon>
          <span>Добавить</span>
        </v-btn>
      </template>
      <v-card class="kit-create-card px-4" outlined>
        <v-form ref="kitForm" @submit.prevent="checkInput">
          <v-card-title>Добавить комплект</v-card-title>
          <v-card-text>
            <span class="font-weight-medium">Серийный номер</span>
            <v-text-field v-model="kit.serialNumber" :rules="serialNumberRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
            <span class="font-weight-medium">Хост</span>
            <v-text-field v-model="kit.host" :rules="hostRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
            <span class="font-weight-medium">Описание</span>
            <v-textarea v-model="kit.description" class="mt-1" hide-details outlined dense />
          </v-card-text>
          <v-card-actions class="mb-2 justify-end">
            <v-btn class="text-none" depressed color="primary" type="submit" :loading="loading"> Добавить </v-btn>
            <v-btn class="mx-2 text-none" text color="grey darken-3" @click="closeDialog">Отмена</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      kit: this.createKitStub(),
      serialNumberRules: [(v) => !!v || 'Обязательное поле'],
      hostRules: [(v) => !!v || 'Обязательное поле']
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    }
  },
  methods: {
    createKitStub() {
      return {
        serialNumber: '',
        host: '',
        description: ''
      }
    },
    checkInput() {
      if (this.$refs.kitForm.validate()) {
        this.loading = true
        this.submitData()
      }
    },
    submitData() {
      this.createKit({ kit: this.kit, projectId: this.projectId })
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },
    closeDialog() {
      this.dialog = false
      this.$refs.kitForm.reset()
      this.kit = this.createKitStub()
    },
    ...mapActions('project', ['createKit'])
  }
}
</script>
<style>
.kit-create-card .v-btn {
  font-weight: 400;
}
</style>
