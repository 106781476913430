<template>
  <v-expansion-panel :class="statusColorClass">
    <v-expansion-panel-header>
      <v-row row class="px-3">
        <v-col>
          <div class="caption grey--text">Номер</div>
          <div>{{ kit.serialNumber }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">Хост</div>
          <div>{{ kit.host }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">Принадлежность</div>
          <div>{{ lastStatus ? locationDescription(lastStatus.kitLocation) : 'Нет данных' }}</div>
        </v-col>
        <v-col>
          <div class="caption grey--text">Статус</div>
          <div>
            <template v-if="areErrorsFound"><v-icon color="error" dense>mdi-alert-outline</v-icon></template>
            <template v-if="areWarningsFound"><v-icon color="warning" dense>mdi-alert-outline</v-icon></template>
            <template v-if="!areErrorsFound && !areWarningsFound">{{ lastStatus ? 'ОК' : 'Нет данных' }}</template>
          </div>
        </v-col>
        <v-col>
          <div class="caption grey--text">Статус получен</div>
          <v-tooltip v-if="lastStatus" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ lastStatus.createdAt | fromNow }}
              </div>
            </template>
            {{ lastStatus.createdAt | format }}
          </v-tooltip>
          <div v-else>Никогда</div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-btn text small color="grey" :to="{ name: 'kit-status', params: { kitId: kit.id } }"> Состояние </v-btn>
      <v-btn text small color="grey" :to="{ name: 'kit-tasks', params: { kitId: kit.id } }"> Файлы </v-btn>
      <KitDialogEdit :kit="kit" :user="user" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from 'moment'
import KitDialogEdit from './KitDialogEdit'
import { mapActions } from 'vuex'

export default {
  props: {
    kit: {
      type: Object,
      required: true,
      default() {
        return { statuses: [] }
      }
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  components: {
    KitDialogEdit
  },
  computed: {
    lastStatus() {
      return this.kit.statuses[0]
    },
    statusColorClass() {
      return this.lastStatus && new Date() - new Date(this.lastStatus.createdAt) < 120000 ? 'online' : 'offline'
    },
    areErrorsFound() {
      return this.lastStatus && this.lastStatus.data.filter((i) => i.errors.length > 0).length > 0
    },
    areWarningsFound() {
      return this.lastStatus && this.lastStatus.data.filter((i) => i.warnings.length > 0).length > 0
    }
  },
  filters: {
    format(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fromNow(date) {
      return moment(date).fromNow()
    }
  },
  methods: {
    editKit() {
      this.dialog = true
    },
    deleteKit() {
      this.loading = true
      this.deleteKit(this.kit.id)
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    locationDescription(location) {
      let description = 'Неизвестно'
      if (location === 1) {
        description = 'Ретропоезд'
      } else if (location === 2) {
        description = 'Беспересадочный'
      } else if (location === 3) {
        description = 'Ленинградский вокзал'
      }
      return description
    },
    ...mapActions({ deleteKit: 'kit/deleteKit' })
  }
}
</script>

<style scoped>
.online {
  border-left: 4px solid lightgreen;
}
.offline {
  border-left: 4px solid lightgray;
}
</style>
