<template>
  <div class="kits">
    <h1 class="subtitle-1 grey--text">Комплекты</h1>

    <KitDialogCreate :user="user" class="text-right" />

    <v-expansion-panels class="mt-4" hover>
      <KitListItem v-for="kit in kits" :key="kit.id" :kit="kit" :user="user" />
    </v-expansion-panels>
  </div>
</template>

<script>
import KitDialogCreate from '@/components/kit/KitDialogCreate'
import KitListItem from '@/components/kit/KitListItem'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    KitDialogCreate,
    KitListItem
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters({
      kits: 'project/kits',
      user: 'auth/user'
    })
  },
  mounted() {
    this.fetchKits(this.projectId)
  },
  methods: {
    ...mapActions('project', ['fetchKits'])
  }
}
</script>
