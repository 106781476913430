<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <template v-slot:activator="{ on }">
      <v-btn :disabled="user.id !== 1" text small color="grey" v-on="on" class="ma-2">
        <v-icon small left>mdi-pencil</v-icon>
        Редактировать
      </v-btn>
    </template>
    <v-card class="kit-edit-card px-4" outlined>
      <v-form ref="kitForm" @submit.prevent="checkInput">
        <v-card-title>
          <span>Изменить комплект</span>
        </v-card-title>
        <v-card-text>
          <span class="font-weight-medium">Серийный номер</span>
          <v-text-field v-model="editingKit.serialNumber" :rules="serialNumberRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense>
          </v-text-field>
          <span class="font-weight-medium">Хост</span>
          <v-text-field v-model="editingKit.host" :rules="hostRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
          <span class="font-weight-medium">Описание</span>
          <v-textarea v-model="editingKit.description" class="mt-1" hide-details outlined dense />
        </v-card-text>
        <v-card-actions class="mb-2">
          <v-btn small text class="mx-2" color="grey darken-2" @click="deleteKit">
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" type="submit" depressed color="primary" class="text-capitalize">Сохранить</v-btn>
          <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    kit: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      editingKit: Object.assign({}, this.kit),
      serialNumberRules: [(v) => !!v || 'Обязательное поле'],
      hostRules: [(v) => !!v || 'Обязательное поле']
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    }
  },
  methods: {
    checkInput() {
      if (this.$refs.kitForm.validate()) {
        this.loading = true
        this.submitData()
      }
    },
    submitData() {
      this.updateKit({ projectId: this.projectId, kit: this.editingKit })
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },
    closeDialog() {
      this.dialog = false
      this.editingKit = Object.assign({}, this.kit)
    },
    deleteKit() {
      this.loading = true
      this.deleteKitById({ projectId: this.projectId, kitId: this.kit.id })
        .then(() => {
          this.loading = false
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    ...mapActions({
      updateKit: 'project/updateKit',
      deleteKitById: 'project/deleteKit'
    })
  }
}
</script>
<style scoped>
.kit-edit-card .v-btn {
  font-weight: 400;
}
</style>
